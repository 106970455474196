import React from 'react'
import _ from 'lodash.deburr'

import FontsResizer from '../../../components/FontsResizer'
import ShareLinks from '../../../components/ShareLinks'
import {
  richTextToBasicHtmlTransformer,
  shareLinksTransformer
} from '../../../transformers'
import { variantTransformer } from '../../../redux/menu/transformers'
import { generateAkamaiUrl } from '../../../helpers/Akamai'


const config = require('../../../configuration')


export const questionsDuMomentTransformer = (item) => item?.questionsDuMomentView?.results?.map((taxonomy) => ({
  text:
      taxonomy?.name.toLowerCase() === 'avant la grossesse'
          ? '<strong>Avant</strong> la grossesse'
          : `${taxonomy?.fieldPeriodesSubtitle ?? ''} <strong>${taxonomy?.name}</strong>`,
  tid: taxonomy?.tid ?? null,
  path:
      (taxonomy?.entityUrl?.path.split('/'))[(taxonomy?.entityUrl?.path.split('/')).length - 1]
      ?? '',
  linkProps: {
    href: taxonomy?.entityUrl?.path ?? '',
  },
  isCurrent: false,
})) ?? []

export const questionsTransformer = (item) => item?.nodeQuery?.entities?.map((question) => question) ?? []

export const headerSimpleQuestionsTransformer = (props, t, variant, route) => {
  const url = `${config.app.APP_URL}${route.asPath}`

  return {
    title: t('questionsPageTitle'),
    text: '',
    variant,
    zoneTools: (
      <>
        <FontsResizer />
        <ShareLinks
          {...shareLinksTransformer(
                  {
                    title: t('questionsPageTitle'),
                    mailToBody: t('shareQuestionBody'),
                    url,
                  },
                  t
              )}
        />
      </>
    ),
  }
}

export const blockQuestionsIntroductionTransformer = (props, t, variant) => ({
  text: `<p>${t('questionsPageIntro')}</p>`,
  textOther: `<h3><span style="color: #12CFB8;">${t('questionsPageSubtitle')}</span></h3>`,
})

export const blockQuestionTransformer = (question, t, variant) => {
  const date = new Date(question?.fieldDate?.value) ?? null

  return {
    textAuthor: richTextToBasicHtmlTransformer(question?.fieldAuthor?.processed ?? ''),
    textDate: date
        ? `${date
            .getDate()
            .toString()
            .padStart(2, '0')} ${t(`month_${date.getMonth()}`)} ${date.getUTCFullYear()}`
        : '',
  }
}

export const questionMessageTransformer = (question, t, variant) => ({
  text: `<h3>${question?.fieldQuestion}</h3>` ?? '',
  image: {
    src: `/images/Common/avatar-${question?.fieldAuteurType === 'maman' ? 'elle' : 'lui'}.png`,
    alt: question?.fieldAuteurType?.toUpperCase(),
  },
  variantType: 'question',
})

export const answerMessageTransformer = (question, t, variant) => ({
  text: question?.fieldAnswer?.processed ?? '',
  image: {
    src: '/images/Common/avatar-1000-jours.png',
    alt: t('siteTitle'),
  },
  variantType: 'answer',
})

export const pushHelpMessageTransformer = (question, t, variant) => ({
  text:
      `<b>${question?.fieldPushHelp?.processed
          ?.replaceAll('<strong>', '<span style="color: #3333FF;">')
          ?.replaceAll('</strong>', '</span>')
          ?.replaceAll('<em>', '<span style="color: #12CFB8;">')
          ?.replaceAll('</em>', '</span>')}</b>` ?? '',
  image: {
    src: '/images/Common/avatar-1000-jours.png',
    alt: t('siteTitle'),
  },
  variantType: 'answer',
})

export const questionPushTransformer = (question, t, variant) => ({
  image: {
    src: '/images/Common/avatar-1000-jours.png',
    alt: t('siteTitle'),
  },
})

export const questionPushArticleExtTransformer = (push, t, variant, index) => {
  const entity = push?.entity ?? null

  return {
    title: entity?.fieldTitle ?? '',
    linkProps: {
      href: entity?.fieldLink?.uri ?? '#',
      target: '_blank',
    },
    text: entity?.fieldBody?.processed,
    image: {
      src: generateAkamaiUrl(entity?.fieldImage?.url ?? ''),
      alt: entity?.fieldImage?.alt ?? '',
    },
    variant: 'default',
  }
}

export const questionPushArticleIntTransformer = (push, t, variant, index) => {
  const field = push?.entity?.fieldArticle?.entity ?? null

  return {
    title: field?.title ?? '',
    linkProps: {
      href: field?.entityUrl?.path ?? '#',
    },
    text: field?.body?.processed ?? '',
    image: {
      src: generateAkamaiUrl(field?.fieldImagePushArticles?.url ?? ''),
      alt: field?.fieldImagePushArticles?.alt ?? '',
    },
    variant: variantTransformer(
        field?.fieldArticleSousRubrique?.entity?.fieldRubriqueClass ?? null
    ),
  }
}

export const questionPushPracticeTransformer = (push, t, variant, index) => {
  const field = push?.entity?.fieldBlocEnPratique?.entity ?? null

  let fieldBlocPratiqueSousEspace = push?.entity?.fieldBlocPratiqueSousEspace ?? null

  if (fieldBlocPratiqueSousEspace === Number(fieldBlocPratiqueSousEspace)) {
    fieldBlocPratiqueSousEspace = null
  } else {
    fieldBlocPratiqueSousEspace = fieldBlocPratiqueSousEspace?.substr(0, fieldBlocPratiqueSousEspace.indexOf('(')).trim()
  }

  let linkHref = field?.entityUrl?.path ?? ''

  if (fieldBlocPratiqueSousEspace) {
    const sousEspaceFormatted = _(fieldBlocPratiqueSousEspace).replace(/\s+/g, '-').toLowerCase()

    linkHref = `${linkHref}/${sousEspaceFormatted}`
  }

  return {
    title: field?.title ?? '',
    subSpace: fieldBlocPratiqueSousEspace ?? '',
    linkProps: {
      href: linkHref,
    },
    image: {
      src: generateAkamaiUrl(field?.fieldIconeEspace?.url ?? ''),
      alt: field?.fieldIconeEspace?.alt ?? '',
    },
    variant: 'default',
  }
}

export const questionPushVideoTransformer = (push, t, variant, index) => {
  const entity = push?.entity ?? null

  const videoUrl = entity?.fieldUrlVideo ?? ''
  const videoType = videoUrl.indexOf('.mp4') !== -1
      ? 'video/mp4'
      : videoUrl.indexOf('.webm') !== -1
          ? 'video/webm'
          : null
  const url = entity?.fieldImageSquare?.entity?.url ?? entity?.fieldImage?.entity?.url

  return {
    textPlay: t('playVideo'),
    image: {
      src: url ? generateAkamaiUrl(url) : '',
      alt: entity?.fieldImage?.alt ?? '',
    },
    videos: [
      {
        src: videoUrl,
        type: videoType,
      },
    ],
    tracks: [],
  }
}
