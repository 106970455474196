import React from 'react'
import Immutable from 'seamless-immutable'

import { stripHtml } from '../../../helpers/StringHelpers'
import { variantTransformer } from '../../../redux/menu/transformers'
import FontsResizer from '../../../components/FontsResizer'
import ShareLinks from '../../../components/ShareLinks'
import { richTextToBasicHtmlTransformer, shareLinksTransformer } from '../../../transformers'
import { generateAkamaiUrl } from '../../../helpers/Akamai'


export const headerHomeTransformer = (t) => ({
  title: t('titleHomepage'),
  text: `<p>${t('textHomepage')}</p>`,
  image: {
    src: t('imageHomepage'),
    alt: t('titleHomepage'),
  },
  textButton: t('heroKnowMore'),
  linkProps: {
    href: t('heroUrl'),
  },
  zoneTools:
  <>
    <FontsResizer />
  </>,
  zoneSharing:
  <>
    <ShareLinks {...shareLinksTransformer({ printJs: false, downloadPdf: false, url: '/' }, t)} />
  </>,
})

export const homepageViewTransformer = (item) => ({
  rubriques: item?.blocListeRubriquesView?.results ?? null,
  questions: item?.blocListeQuestionsDuMomentView?.results ?? null,
})

export const blockHomeTopicTransformer = (rubriques, options = {}) => ({
  topics: rubriques?.map((rubrique) => ({
    title: rubrique?.title.replace('|', '<br />') ?? '',
    variant: variantTransformer(rubrique?.fieldSousRubrique[0]?.entity?.fieldRubriqueClass ?? ''),
    text: stripHtml(rubrique?.body?.value ?? ''),
    linkProps: {
      href: rubrique?.entityUrl?.path ?? '',
    },
    image: rubrique?.fieldImage?.fieldImageRubHome ? {
      src: generateAkamaiUrl(rubrique?.fieldImage?.fieldImageRubHome?.url ?? ''),
      alt: rubrique?.fieldImage?.alt ?? '',
    } : null,
    ...options,
  })),
})

export const blockHomeQuestionTransformer = (questions, options = {}, t) => {
  const questionsTransformed = questions?.map((question) => {
    const date = new Date(question?.fieldDate?.value)

    return {
      period: `#${question?.fieldPeriode?.entity?.entityLabel}`,
      rawDate: date,
      date: `${date.getDate().toString().padStart(2, '0')} ${t(`month_${date.getMonth()}`)} ${date.getUTCFullYear()}`,
      author: richTextToBasicHtmlTransformer(question?.fieldAuthor?.processed ?? ''),
      text: question?.fieldQuestion ?? '',
      link: {
        text: t('questionsDiscoverAnswer'),
        linkProps: {
          route: `${t('questionsUri')}/${question?.fieldPeriode?.entity?.path?.alias.substr(1)}#question_nid_${question?.nid}`,
        },
      },
      ...options,
    }
  })

  const questionsTransformedOrderedDesc = Immutable.asMutable(questionsTransformed).sort((a, b) => b.rawDate - a.rawDate)

  return {
    title: t('questionsHpTitle'),
    text: `<p>${t('questionsHpText')}</p>`,
    linkMore: {
      text: t('questionsHpCtaMore'),
      linkProps: {
        route: t('questionsUri'),
      },
    },
    questions: questionsTransformedOrderedDesc,
  }
}
