import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'

import withMemo from '../../decorators/withMemo'
import { mergeStyles } from '../../utils/StylesUtils'
import { safeCall } from '../../helpers/React'

import styles from './styles'


const useStyles = createUseStyles(styles)

const FontsResizerButton = (props) => {
  const {
    classes: classesProp,
    className,
    textLetter,
    textResize,
    // eslint-disable-next-line no-unused-vars
    displaySize,
    targetSize,
    onResizeHandler,
  } = props
  const classesComp = useStyles(props)
  const classes = useMemo(() => mergeStyles(classesComp, classesProp), [classesProp, classesComp])

  const handleClick = useCallback(() => {
    safeCall(onResizeHandler, targetSize)
  }, [onResizeHandler, targetSize])

  return (
    <button
      type="button"
      className={cx(classes.container, className)}
      onClick={handleClick}
    >
      <i>{textResize}</i>
      <span>{textLetter}</span>
    </button>
  )
}

FontsResizerButton.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  textResize: PropTypes.string,
  textLetter: PropTypes.string,
  displaySize: PropTypes.number,
  targetSize: PropTypes.number,
  onResizeHandler: PropTypes.func,
}

FontsResizerButton.defaultProps = {
  className: null,
  classes: null,
  textResize: '',
  textLetter: 'A',
  displaySize: 16,
  targetSize: 11,
  onResizeHandler: () => null,
}

export default withMemo()(FontsResizerButton)
